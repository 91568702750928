import React, { FC } from "react";
import { graphql, Link as GatsbyLink, navigate, PageProps } from "gatsby";
import classNames from "classnames";
import { Button, Card, IconButton, Heading, Label } from "@qwilr/kaleidoscope";
import { ArrowLeft, ArrowRight, Download } from "@qwilr/kaleidoscope/icons";
import { ASSET_LIBRARY_URL } from "../../components/AssetLibrary/AssetLibrary";

interface AssetLayoutProps extends PageProps {
  data: any;
  location: any;
}

const AssetLayout: FC<AssetLayoutProps> = ({ data, pageContext, location }) => {
  const {
    file: { publicURL, name, prettySize, ext },
  } = data;

  return (
    <div className="kld-asset">
      <div className="kld-asset__content">
        <header className="kld-asset__header">
          <div className="kld-asset__title">
            <IconButton
              icon={<ArrowLeft />}
              onClick={() => {
                const backQuery = new URLSearchParams();

                Object.keys(location.state || {}).forEach((key) => {
                  const value = location.state[key];

                  if (((key === "category" && value !== "none") || (key !== "category" && !!value)) && key !== "key") {
                    backQuery.set(key, location.state[key]);
                  }
                });

                navigate(`${ASSET_LIBRARY_URL}${backQuery.toString() !== "" ? `?${backQuery}` : ""}`);
              }}
            />
            <Heading level="3" element="h1">
              {name}
            </Heading>
          </div>
          <Button
            onClick={() => {
              const tempLink = document.createElement("a");
              tempLink.download = name;
              tempLink.href = publicURL;
              tempLink.click();
            }}
          >
            <Download />
            Download
          </Button>
        </header>

        <Card className="kld-asset__card">
          <img className="kld-asset__preview" src={publicURL} alt={name} />
        </Card>

        <div className="kld-asset__meta">
          <Label className="kld-asset__meta-item">{prettySize}</Label>
          <Label className="kld-asset__meta-item">{ext}</Label>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query AssetByPublicURL($id: String) {
    file(id: { eq: $id }) {
      publicURL
      name
      ext
      prettySize
      relativeDirectory
    }
  }
`;

export default AssetLayout;
